import React from "react";

import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import "@fontsource/abel"; // Agency FB

import { Navigation } from "./index";


interface BuyNowCardProps {
  price: number;
  quantity: string;
  img: () => JSX.Element;
  title: string;
}

const YellowPills = (): JSX.Element => (
  <StaticImage
    alt="Yellow pills."
    src="../../../../images/cbd-now-yellow-pills.jpg"
    loading="eager"
    objectFit="cover"
  />
);

const EyeDropper = (): JSX.Element => (
  <div className="relative">
    <div className="absolute top-0 left-0 -mt-5 rounded-xl border border-cbd-now-green text-center bg-white text-cbd-now-green px-3 py-2 z-10">
      Unflavored
    </div>
    <StaticImage
      alt="Eye dropper."
      src="../../../../images/cbd-now-eye-dropper.jpg"
      loading="eager"
      objectFit="cover"
    />
  </div>
);

const BodyLotion = (): JSX.Element => (
  <StaticImage
    alt="Body lotion."
    src="../../../../images/cbd-now-body-lotion.jpg"
    loading="lazy"
    objectFit="cover"
  />
);

const GummyBears = (): JSX.Element => (
  <StaticImage
    alt="Gummy bears."
    src="../../../../images/cbd-now-gummy-bears.jpg"
    loading="lazy"
    objectFit="cover"
  />
);

const BuyNowCard = ({ img, title, quantity, price }: BuyNowCardProps): JSX.Element => {
  const Img: () => JSX.Element = img;

  return (
    <div className="bg-gray-300 max-w-sm rounded-xl p-5 pt-8">
      <Img />
      <div className="flex flex-col space-y-1 text-center w-full">
        <p className="font-bold">{title}</p>
        <p className="text-xs">{quantity}</p>
        <p>${price}</p>
        <button className="mx-16 px-10 py-1 rounded-lg bg-cbd-now-green text-white outline-none focus:ring-4 focus:ring-gray-500">BUY NOW</button>
      </div>
    </div>
  );
};

const BuyNowCards = (): JSX.Element => {
  const cards: BuyNowCardProps[] = [
    {
      img: YellowPills,
      price: 44.95,
      quantity: "25 capsules",
      title: "CBD NOW SOFTGELS",
    },
    {
      img: YellowPills,
      price: 95.99,
      quantity: "75 capsules",
      title: "CBD NOW SOFTGELS"
    },
    {
      img: EyeDropper,
      price: 56.99,
      quantity: "1oz. (25mg)",
      title: "CBD NOW TINCTURE"
    },
    {
      img: EyeDropper,
      price: 49.99,
      quantity: "1 oz. (12mg)",
      title: "CBD NOW TINCTURE"
    },
    {
      img: BodyLotion,
      price: 18.99,
      quantity: "8 oz.",
      title: "CBD NOW BODY LOTION"
    },
    {
      img: GummyBears,
      price: 24.99,
      quantity: "30 pieces",
      title: "CBD NOW GUMMIES"
    }
  ];

  return (
    <>
      {cards.map((card, index) => (
        <BuyNowCard
          title={card.title}
          quantity={card.quantity}
          price={card.price}
          img={card.img}
          key={index}
        />
      ))}
    </>
  );
};

const Body = (): JSX.Element => {
  return (
    <div className="bg-gray-100 px-10 py-10 flex-grow">
      <h2 className="text-4xl">CBD Oil</h2>
      <p>CBD can be taken in many ways—from smoking and vaping to placing oil or tincture under the tongue. It is also available in pills or edible products like gummies as well as in topical creams and patches. CBD products are sold as over-the-counter preparations.</p>
      <p className="mt-5">All our products are VEGAN, GLUTEN-FREE, and NOT tested on animals. Unlike products from other companies, our CBD products do not contain harmful contaminants. </p>
      <h2 className="text-4xl">Best Sellers</h2>
      <div className="grid grid-flow-row grid-cols-1 md:grid-cols-3 gap-6 mt-5">
        <BuyNowCards />
      </div>
    </div>
  );
};

const CBDNowPage = (): JSX.Element => {
  return (
    <div className="flex flex-col min-h-screen" style={{ fontFamily: "Abel" }}>
      <Navigation />
      <Body />
      <Navigation />
    </div>
  );
};

export default CBDNowPage;
